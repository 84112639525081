.team {
  padding-top: 75px;
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .team {
    padding-bottom: 10px;
  }
  .textContainerTeam {
    padding-top: 20px;
  }
}
