.notFound {
  height: 100vh;
  width: 100vw;
  background-color: #c37753;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .notFound {
    padding-top: 25%;
  }
}
