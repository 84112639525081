.bannerImg {
  display: flex;
  justify-content: center;
  height: 90px;
  filter: drop-shadow(0px 0px 15px #c27753);
  /* margin: 10px 0; */
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .bannerImg {
    height: 50px;
    margin: 10px 0;
  }
}
