.footer {
  clear: both;
  /* position: absolute; */
  background-color: transparent;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.footer a {
  color: sienna;
  text-decoration: none;
}

.footer a:hover {
  color: indianred;
  text-decoration: underline;
  cursor: url(../../assets/images/cursor.png), pointer;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    padding-bottom: 15px;
  }
}
