.services {
  padding-top: 5px;
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .services {
    padding-bottom: 0px;
  }
}
