.hero {
  background-image: url("../../assets/images/hero5.jpg");
  background-size: cover;
  width: 100%;
  height: 70vh;
  margin-top: -76px;
}

.logoText {
  padding-top: 10%;
  max-width: 50%;
  filter: drop-shadow(0px 0px 5px #c27753);
}

.tagline {
  font-size: larger;
  padding-left: 4%;
  filter: drop-shadow(0px 0px 5px #fbe5c0);
}

/* tablets */
@media screen and (min-width: 425px) and (max-width: 767px) {
  .logoText {
    padding-top: 10%;
    max-width: 100%;
  }
  .tagline {
    padding-left: 9%;
  }
}
/* phone screens */
@media screen and (min-width: 320px) and (max-width: 424px) {
  .logoText {
    padding-top: 30%;
    max-width: 100%;
  }
  .tagline {
    padding-left: 8%;
  }
}
