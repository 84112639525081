.about {
  padding-top: 75px;
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .about {
    padding-bottom: 10px;
  }

  .textContainerAbout {
    padding-top: 20px;
  }
}
