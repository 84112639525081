.approach {
  padding-top: 5px;
}

/* mobile screens */
@media screen and (min-width: 320px) and (max-width: 767px) {
  .textContainerApproach {
    padding-bottom: 20px;
  }
  .approachList {
    padding-top: 25px;
  }
}
