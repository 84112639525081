.landingPage {
  background-color: bisque;
  font-family: "Raleway", san-serif;
  color: black;
}

h1,
h3 {
  color: #0e404b;
  filter: drop-shadow(0px 0px 5px #c27753);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(5px 5px 15px black);
}
